<template>
	<div class="app-container">
		<el-card class="box-card" id="subscriptions">
			<div slot="header" class="clearfix">
				<span>{{ $t('account.notifications.title') }}</span>
			</div>
			<div class="body">
				<span class="flag-icon flag-icon-ro"></span>
				<!-- <span class="flag-icon flag-icon-ro flag-icon-squared"></span> -->

				<el-table v-loading="loading" :data="notificationsList" class="fullWidth" border>
					<el-table-column :label="$t('account.notifications.column-notification')">
						<template slot-scope="scope">
							<div class="notification-title">{{ scope.row.title }}</div>
							<div class="notification-description">{{ scope.row.description }}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('account.notifications.column-language')" width="100">
						<template slot-scope="scope">
							<span v-for="(language, index) in scope.row.languages" :key="index" class="badge-language">{{
								language.code.toUpperCase()
							}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="category" :label="$t('account.notifications.column-category')" width="200"></el-table-column>
					<el-table-column :label="$t('account.notifications.column-type')" width="200">
						<template slot-scope="scope">
							<div v-for="(type, index) in scope.row.types" :key="index">{{ type.name }}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('account.notifications.column-email')" width="200">
						<template slot-scope="scope">
							<div v-for="(email, index) in scope.row.emails" :key="index" class="notification-email">
								<span class="badge-language">{{ email.language.toUpperCase() }}</span>
								{{ email.email }}
							</div>
						</template>
					</el-table-column>
					<el-table-column width="65">
						<template slot-scope="scope">
							<el-button
								v-if="hasEmail(scope.row.types)"
								@click="showModal(scope.row.id)"
								type="primary"
								icon="el-icon-view"
							></el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-dialog :title="$t('account.notifications.modal.title')" :visible.sync="modal.visible" :before-close="resetModalForm" width="40%">
					<div v-loading="modal.loading">
						<el-form :model="modal.form" :rules="modal.formRules" ref="modalForm" label-position="left" label-width="100px">
							<el-form-item :label="$t('account.notifications.modal.email')" prop="email">
								<el-input v-model="modal.form.email" size="small"></el-input>
							</el-form-item>

							<el-form-item :label="$t('account.notifications.modal.language')" prop="language_id">
								<el-radio-group v-model="modal.form.language_id">
									<el-radio v-for="(language, index) in languages" :key="index" :label="language.id" size="small" border>{{
										language.name
									}}</el-radio>
								</el-radio-group>
							</el-form-item>

							<el-form-item>
								<el-button @click="addNotificationAssoc" type="primary">{{ $t('account.notifications.modal.submit') }}</el-button>
							</el-form-item>
						</el-form>

						<el-table :data="modal.emails" border style="width: 100%">
							<el-table-column prop="email" :label="$t('account.notifications.modal.email')"></el-table-column>
							<el-table-column :label="$t('account.notifications.modal.language')">
								<template slot-scope="scope">
									<span class="badge-language">{{ scope.row.language.toUpperCase() }}</span>
								</template>
							</el-table-column>
							<el-table-column width="65">
								<template slot-scope="scope">
									<el-button @click="deleteNotificationAssoc(scope.row.id)" type="danger" icon="el-icon-delete"></el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-dialog>
			</div>
		</el-card>
	</div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
	data() {
		return {
			loading: true,
			modal: {
				visible: false,
				loading: true,
				notification_id: 0,
				form: {
					email: '',
					language_id: ''
				},
				formRules: {
					email: [
						{ required: true, message: this.$t('account.notifications.modal.error-email'), trigger: 'blur' },
						{ type: 'email', message: this.$t('account.notifications.modal.error-email'), trigger: 'blur' }
					],
					language_id: [{ required: true, message: this.$t('account.notifications.modal.error-language'), trigger: 'blur' }]
				},
				emails: []
			}
		}
	},
	computed: {
		notificationsList: get('account/notifications@list'),
		languages: get('app/languages')
	},
	methods: {
		getNotificationsList: call('account/GetNotificationsList'),
		// getNotificationsList() {
		// 	this.$store
		// 		.dispatch('account/GetNotificationsList')
		// 		.then(() => {
		// 			this.loading = false
		// 		})
		// 		.catch((e) => {
		// 			this.$reportToSentry(e, {
		// 				extra: {
		// 					fn: 'getNotificationsList'
		// 				}
		// 			})
		// 			this.loading = false
		// 		})
		// },
		getNotificationsAssoc() {
			this.modal.loading = true

			this.$store
				.dispatch('account/GetNotificationsAssoc', {
					notification_id: this.modal.notification_id
				})
				.then((response) => {
					this.modal.emails = response.message
					this.modal.loading = false
				})
				.catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'editNotification',
							params: {
								notification_id: this.modal.notification_id
							}
						}
					})
					this.modal.loading = false
				})
		},
		addNotificationAssoc() {
			this.$refs['modalForm'].validate((valid) => {
				if (valid) {
					this.modal.loading = true

					this.$store
						.dispatch('account/AddNotificationAssoc', {
							notification_id: this.modal.notification_id,
							email: this.modal.form.email,
							language_id: this.modal.form.language_id
						})
						.then((response) => {
							if (response.message.success == true) {
								this.sbMsg({
									type: 'success',
									message: this.$t('account.notifications.email-added')
								})

								// Reload lists
								this.getNotificationsList()
								this.getNotificationsAssoc()

								// Reset form
								this.resetModalForm()
							} else {
								this.sbMsg({
									type: 'error',
									message: response.message.error
								})
							}
							this.modal.loading = false
						})
						.catch((e) => {
							this.$reportToSentry(e, {
								extra: {
									fn: 'addNotificationAssoc',
									params: {
										notification_id: this.modal.notification_id,
										email: this.modal.form.email,
										language_id: this.modal.form.language_id
									}
								}
							})
							this.modal.loading = false
						})
				} else {
					return false
				}
			})
		},
		deleteNotificationAssoc(id) {
			this.modal.loading = true

			this.$store
				.dispatch('account/DeleteNotificationAssoc', {
					id: id
				})
				.then((response) => {
					if (response.message.success == true) {
						this.sbMsg({
							type: 'success',
							message: this.$t('account.notifications.email-deleted')
						})

						// Reload lists
						this.getNotificationsList()
						this.getNotificationsAssoc()

						this.modal.loading = false
					}
				})
				.catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'deleteNotificationAssoc',
							params: {
								id: id
							}
						}
					})
					this.modal.loading = false
				})
		},
		showModal(notification_id) {
			this.modal.notification_id = notification_id
			this.getNotificationsAssoc()
			this.modal.visible = true
		},
		resetModalForm(done) {
			this.$refs['modalForm'].resetFields()
			done()
		},
		hasEmail(types) {
			return types.some((type) => type['id'] == 1)
		}
	},
	created() {
		this.getNotificationsList().then(res => {
			this.loading = false
		})
		// this.getNotificationsList()
	}
}
</script>

<style lang="scss" scoped>
.notification-title {
	color: #f05e20;
	font-size: 14px;
	font-weight: 600;
}

.notification-email + .notification-email {
	margin-top: 3px;
}

.badge-language {
	background: #9f9e9e;
	color: #fff;
	font-weight: 600;
	line-height: 1;
	font-size: 12px;
	width: 24px;
	height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 50%;
}

.badge-language + .badge-language {
	margin-left: 5px;
}
</style>
